import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import styles from "./Document.module.scss";
import iconHot from "../../../assets/images/hot.gif";
import Card from "../../Elements/Card/Card";
import CardM from "../../Elements/Card_m/Card";

import chemistry from "../../../assets/images/Course/hdc.png";
import Calculus1 from "../../../assets/images/Course/GKGT1.png";
import calculus2 from "../../../assets/images/Course/gt2ct.png";

import physic1 from "../../../assets/images/Course/GTVL1.png";
import dstt from "../../../assets/images/Course/GKDSTT.png";
import PPT from "../../../assets/images/Course/pptck.png";

import physic2 from "../../../assets/images/Course/vl2.png";
import xstk from "../../../assets/images/Course/xacsuatthongke.png";

//
import khoa from "../../../assets/images/khoa.png";
import Csharp from "../../../assets/images/CourseFree/Csharp.jpg";
import ui from "../../../assets/images/CourseFree/UI-va-UX.jpg";
import ai from "../../../assets/images/CourseFree/ai.jpg";
import bigdata from "../../../assets/images/CourseFree/bigdata.jpg";
import cisco from "../../../assets/images/CourseFree/cisco.jpg";
import cplus from "../../../assets/images/CourseFree/cplus.png";
import seo from "../../../assets/images/CourseFree/deo.jpg";
import docker from "../../../assets/images/CourseFree/docker.png";
import excel from "../../../assets/images/CourseFree/excel.png";
import excelpro from "../../../assets/images/CourseFree/excelpro.jpg";
import figma from "../../../assets/images/CourseFree/figma.png";
import gg from "../../../assets/images/CourseFree/gg.jpg";
import giaithuat from "../../../assets/images/CourseFree/giaithuat.jpg";
import hacker from "../../../assets/images/CourseFree/hacker.png";
import ios from "../../../assets/images/CourseFree/ios.jpg";
import java from "../../../assets/images/CourseFree/java.jpeg";
import jquery from "../../../assets/images/CourseFree/jquery-1.jpg";
import js from "../../../assets/images/CourseFree/js.png";
import kotlin from "../../../assets/images/CourseFree/kotlin.jpg";
import laravel from "../../../assets/images/CourseFree/laravel.jpg";
import linux from "../../../assets/images/CourseFree/linux.jpg";
import lofi from "../../../assets/images/CourseFree/lofi.jpg";
import python from "../../../assets/images/CourseFree/python.png";
import php from "../../../assets/images/CourseFree/php.jpg";
import ppt from "../../../assets/images/CourseFree/ppt.jpg";
import ps from "../../../assets/images/CourseFree/ps.jpg";
import native from "../../../assets/images/CourseFree/native.jpg";
import reactjs from "../../../assets/images/CourseFree/reactjs.jpg";
import robot from "../../../assets/images/CourseFree/robot.jpg";
import web_services from "../../../assets/images/CourseFree/web_services.jpg";
import word from "../../../assets/images/CourseFree/word.png";
import word_pro from "../../../assets/images/CourseFree/word_pro.jpg";
import wordpress from "../../../assets/images/CourseFree/wordpress.jpg";
import etab from "../../../assets/images/CourseFree/etab.png";
import oop from "../../../assets/images/CourseFree/oop.png";
import robotics from "../../../assets/images/CourseFree/robotics.jpg";
//
import av from "../../../assets/images/newfeed/av.jpg";
import livegt1 from "../../../assets/images/newfeed/livegt1.png";
import daicuong from "../../../assets/images/newfeed/daicuong.png";
import qp from "../../../assets/images/newfeed/qp.jpg";
import sale from "../../../assets/images/newfeed/sale.png";
import cntt from "../../../assets/images/newfeed/cntt.jpg";
import sach from "../../../assets/images/newfeed/sach.png";
import dsttc1 from "../../../assets/images/newfeed/dsttc1.png";

import geogebra_course from "../../../assets/images/Course/geogebra.png";

//course
//video
import img_1 from "../../../assets/images/Video/img1.png";
import img_2 from "../../../assets/images/Video/img2.png";
import matlab from "../../../assets/images/Video/matlab.png";
import img_4 from "../../../assets/images/Video/img4.png";
import img_5 from "../../../assets/images/Video/img5.png";
import img_6 from "../../../assets/images/Video/img6.png";
import geogebra from "../../../assets/images/Video/geogebra.png";
import casio from "../../../assets/images/Video/casio.png";
//
//  import gkxstk from "../../../assets/images/Course/GKXSTK.png";
// import gkdstt from "../../../assets/images/Course/GKDSTT.png";
// import gkPPT from "../../../assets/images/Course/GKPPT.png";
// import gkgt1 from "../../../assets/images/Course/GKGT1.png";
// import gkvl1 from "../../../assets/images/Course/GTVL1.png";
//
//document
import matlab1 from "../../../assets/images/document/matlab.png";
import c from "../../../assets/images/document/c.jpg";
import mevn from "../../../assets/images/document/mevn.jpg";
import Button from "../../Elements/Button/Button";
const cx = classNames.bind(styles);
function Document() {
  const CoursesDefault = [
    {
      id: "gt2",
      courseName: "Lớp cấp tốc  Giải Tích 2 GK",
      tutor: "A.Vương",
      price: 150,
      oldPrice: 500,
      lesson: 28,
      member: "1005+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: calculus2,
      soon: false,
      discount:
        "(oisp+đại trà, tổng thơi gian gần 8 tiếng, đầy đủ lấy 10, giải full các đề)",
    },
    {
      id: "dstt",
      courseName: "Lớp cấp tốc  Đại Số Tuyến Tính GK",
      tutor: "A.Vương",
      price: 150,
      oldPrice: 400,
      lesson: 26,
      member: "485+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: dstt,
      soon: false,
      discount:
        "(oisp+đại trà, tổng thơi gian gần 7 tiếng, đầy đủ lấy 10, giải full các đề)",
    },
    {
      id: "gt1",
      courseName: "Lớp cấp tốc giải tích 1 GK ",
      tutor: "A.Vương",
      price: 150,
      oldPrice: 400,
      lesson: 26,
      member: "485+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: Calculus1,
      soon: false,
      discount:
        "(oisp+đại trà, tổng thơi gian gần 8 tiếng, đầy đủ lấy A-A+, giải full các đề)",
    },

    {
      id: "ppt",
      courseName: "Lớp cấp tốc Phương Pháp Tính A+",
      tutor: "A.Vương",
      price: 150,
      lesson: 21,
      member: "156+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      oldPrice: 400,
      img: PPT,
      soon: false,
      discount:
        "(oisp+đại trà, tổng thơi gian gần 6 tiếng, đầy đủ lấy 9-10 điểm, giải full các đề)",
    },
    {
      id: "xstk",
      courseName: "Lớp Xác Suất Thống Kê A+",
      tutor: "A.Vương",
      price: 200,
      oldPrice: 500,
      lesson: 24,
      member: "401+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: xstk,
      soon: false,
      discount:
        "(được tặng BTL xstk mẫu, btl chính trị và 2 khóa học chuyên ngành)",
    },

    {
      id: "hdc",
      courseName: "Lớp Hóa Đại Cương Pro",
      tutor: "A.Vương",
      price: 350,
      oldPrice: 400,
      img: chemistry,
      lesson: 24,
      member: "436+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      soon: false,
      discount:
        "(Trước 20/2/2025 tặng btl các môn gt2,vl2,đstt,hóa,vlbd,..báo cáo tnvl và 1 lớp giúp hỗ trợ BTL gt2)",
    },
    {
      id: "vl2",
      courseName: "Lớp GK vật lý 2 A Long",
      tutor: "A.Long",
      price: 175,
      oldPrice: 550,
      lesson: 22,
      member: "686+",
      linkRegister: "https://www.facebook.com/profile.php?id=100007913497459",
      img: physic2,
      soon: false,
      discount: "(giá chỉ áp dụng đăng ký trước 15/1)",
    },
    {
      id: "gt2",
      courseName: "Lớp geogebra hỗ trợ BTL GT2",
      tutor: "A.Vương",
      price: 350,
      oldPrice: 500,
      lesson: 24,
      member: "401+",
      linkRegister: "https://www.facebook.com/profile.php?id=100086390823801",
      img: geogebra_course,
      soon: false,
      discount: "(lớp miên phí cho học viên đã đki 1 trong 4 lớp Pro kì 242)",
    },
  ];
  const NewfeedsDefault = [
    {
      name: "Tổng hợp tài liệu các khoa",
      id: "cse",
      img: khoa,
      path: "/home/550",
    },
    {
      name: "Tài liệu và record livestream tích phân kép kỳ 2",
      id: "cse",
      img: livegt1,
      path: "https://drive.google.com/drive/folders/19oLlmuWJXM9A2WwIUSvasub0NoEG4g4i?usp=sharing",
    },
    {
      name: "Record và tài liệu live BTL Chương cấu tạo nguyên tử",
      id: "cse",
      img: sale,
      path: "https://drive.google.com/drive/folders/1hLtGXEdcyG2VLv2ReN8G1ceGpPavnntC?usp=sharing",
    },
    {
      name: "Tài liệu anh văn xếp lớp",
      id: "cse",
      img: av,
      path: "https://drive.google.com/drive/folders/1g3XM0T4WAn8YbTx1wfqePpqfuidqgQ5o?fbclid=IwAR2r6xbOV2qti8P0dp3VV3Zj9Tl7usp_yCsiQnj_S_p_kXSV2lyUvZKBNM4",
    },
    {
      name: "Tài liệu ôn tin học đầu vào 2024",
      id: "cse",
      img: cntt,
      path: "https://drive.google.com/open?id=1oHY2HzYgQeKfE4kpgq9DknNZV3bI0Hpv&usp=drive_copy",
    },

    {
      name: "K24 năm nhất nên mua sách nào??",
      id: "cse",
      img: matlab,
      path: "https://www.youtube.com/watch?v=hH3-MdpkSEM&list=PL8RXTGoT1e8OtUFtC-_33z7QUp9fPpPDg&index=6",
    },
    {
      name: "Tài liệu thi quân sự",
      id: "cse",
      img: qp,
      path: "https://drive.google.com/open?id=1C7PBPHo92uegiIH6tjriy6LALLmFHPn9&usp=drive_copy",
    },

    {
      name: "[Video Đại số tuyến tính]: Chương 1 Ma trận",
      id: "cse",
      img: dsttc1,
      path: "https://www.youtube.com/watch?v=GR077ZJiZPI&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn&index=2",
    },
  ];
  const CourseFreeDefault = [
    {
      name: "Lập trình C++",
      img: cplus,
      path: "https://drive.google.com/drive/folders/136bCo-GUPvaIB3IufFdGyz4wfiVLkmne?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Hướng đối tượng C++",
      img: oop,
      path: "https://drive.google.com/drive/folders/12VA9oAtAolPLmlnZpG-gQj1t1U-WPqfh?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Lập trình C#",
      img: Csharp,
      path: "https://drive.google.com/drive/folders/1Sgl0gVAkyjtVSrc4nkLQOQL5SPGD_ibw?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Học Excel Pro",
      img: excelpro,
      path: "https://drive.google.com/drive/folders/1t5vOmXOhP2XBbPNJxY6tkKY3KVMMoe5E?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },

    {
      name: "Lập trình Python Bootcamp",
      img: python,
      path: "https://drive.google.com/drive/folders/1AR3zTbgV-zsUhQkgsedJiOyhfLn8Y3Ka?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Machine Learning",
      img: ai,
      path: "https://drive.google.com/drive/folders/1JjJDT6F6HTv9R9zTtiuXapbmqin6t9FR?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Cấu trúc và giải thuật",
      img: giaithuat,
      path: "https://drive.google.com/drive/folders/1dkeWP9NNC_PUtVznNrOM5QHSqCQY6O40?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Lập trình Javascript",
      img: js,
      path: "https://drive.google.com/drive/folders/1ZznQ2u2PeJX10LgBSPEKIKFHcPn9hQ4W?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Etabs 2017 Thiết kê, xây dựng mô hình công trình",
      img: etab,
      path: "https://drive.google.com/drive/folders/1i1j6tPDu9kxhcfzrArUr6MUexl6aEXsk",
    },
    {
      name: "Lập trình điều khiển Robotics bằng bluetooth",
      img: robotics,
      path: "https://drive.google.com/drive/folders/1TmTOGa0Oewp_9X2gzWYVXFMgi_3Vqpvt?usp=sharing",
    },
    {
      name: "Học Powerpoint",
      img: ppt,
      path: "https://drive.google.com/drive/folders/1pXQ7SwBOuqIqvpo1rE6ciiE4W7pIYoLU",
    },
    {
      name: "Lập trình Java",
      img: java,
      path: "https://drive.google.com/drive/folders/1sFYapGBEiW8V3gZ49C19K-8TdmQqdoKV?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Lập trình Laravel",
      img: laravel,
      path: "https://drive.google.com/drive/folders/1Afb19GYKP5L0HOiu7QM5Ke733SKmLEbJ?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },

    {
      name: "Lập trình PHP",
      img: php,
      path: "https://drive.google.com/drive/folders/1zLT0p8Ym0HUyqsnDfL3K0KY79qiE1Kij",
    },
    {
      name: "Lập trình Kotlin",
      img: kotlin,
      path: "https://drive.google.com/drive/folders/1TWapEJalYKiLdyNKJwbor75zofqTd-iy",
    },

    {
      name: "Cách search google hiệu quả",
      img: gg,
      path: "https://drive.google.com/drive/folders/1OYgILXUowJySBw5FuRiszkHm1ML0h2lx?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Thiết kế UI/UX",
      img: ui,
      path: "https://drive.google.com/drive/folders/1cOHwywc3NqHupcnTijAFV72QZ4cyw4H_?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "SEO web hiệu quả",
      img: seo,
      path: "https://drive.google.com/drive/folders/1yYhbk3zvBeRNHm5PBp05dU8xu0AqIfgw?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Frontend ReactJs",
      img: reactjs,
      path: "https://drive.google.com/drive/folders/1JZnXEy2ufMG8hlVc1P_3vUQZIXEWMomG?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Quản trị mạng Cisco CCNA",
      img: cisco,
      path: "https://drive.google.com/drive/folders/1NhpGleInJwWeFAYN_cjJapXZmn6_BRP5?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Học Photoshop",
      img: ps,
      path: "https://drive.google.com/drive/folders/1V-egBqoLbYiUSMr_yIk4gAntytCQpMvu?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Làm nhạc Lofi",
      img: lofi,
      path: "https://drive.google.com/drive/folders/1FxRfP_bWa1KYY5mI93NINRzOUR-S7wqt?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Học excel",
      img: excel,
      path: "https://drive.google.com/drive/folders/14v0VuxkLGyFq7bs8xWuOsU-ZuBIXaSwN",
    },
    {
      name: "Hệ điều hành Linux",
      img: linux,
      path: "https://drive.google.com/drive/folders/1TC6hJmi_IvCoclfSwt7IGuuxM2vECMdY?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Lập trình webservices",
      img: web_services,
      path: "https://drive.google.com/drive/folders/19XC-0pX-SQSWMgRu55UTAEFBRXHzzfxX?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Lập trình WordPress",
      img: wordpress,
      path: "https://drive.google.com/drive/folders/1-1kaVMfn-dvS3UOqjyclw8A4eB1cFtli?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Lập trình React native",
      img: native,
      path: "https://drive.google.com/drive/folders/1_u6CMOZH63Eps9nQhizTjqVofXdxWsmL?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Học word",
      img: word_pro,
      path: "https://drive.google.com/drive/folders/17XN1_-RhSxfzKjb3bBYaGQOx4ClXOmy8",
    },
    {
      name: "Lập trình IOS",
      img: ios,
      path: "https://drive.google.com/drive/folders/1JnZnR0xnXKVof30RIx9daI0hJRXtRVgl?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },

    {
      name: "Điều khiển cách tay Robot",
      img: robot,
      path: "https://drive.google.com/drive/folders/13DgjRFHCjnypkg_LsnWClJz7DH3a_bxb?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Khóa học Word Pro",
      img: word,
      path: "https://drive.google.com/drive/folders/1N_rZNInpJ6Djkp5Qe3vKvgFi6rwhj0oP?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Học Jquery",
      img: jquery,
      path: "https://drive.google.com/drive/folders/1M-fneiXRB_3eEsiEOtQmXChWFjGjS1BU?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Thiết kế Figma",
      img: figma,
      path: "https://drive.google.com/drive/folders/1wuebT1m8z7aLVcBXClNvDDrTNBFkmVfz?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Hacker mũ trắng",
      img: hacker,
      path: "https://drive.google.com/drive/folders/1PGf93Xot2GppeeSC52kxJB97iXtrlLSC?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Docker cơ bản",
      img: docker,
      path: "https://drive.google.com/drive/folders/1ysj0uxucsx3hb9zKz03CLZdTlDPoXlLI?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },

    {
      name: "BigData",
      img: bigdata,
      path: "https://drive.google.com/drive/folders/1e1dj_EB0R4BGt6qHCx4F53bjj8UEjY-U?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
  ];

  const VideoDefault = [
    {
      name: "Review các môn đại cương Bách Khoa",
      img: img_5,
      path: "https://www.youtube.com/watch?v=X5bQszvhz5U",
    },
    {
      name: "Casio Định thức có tham số m",
      img: casio,
      path: "https://www.youtube.com/watch?v=2Mdl1xAxmkQ&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn",
    },
    {
      name: "Giải đề giữa kì giải tích 2",
      img: img_1,
      path: "https://www.youtube.com/watch?v=1u0zAyM8Cxo&list=PL8RXTGoT1e8PwIsc4dsaEQK8CQP8YfCVC",
    },
    {
      name: "Geogebra",
      img: geogebra,
      path: "https://www.youtube.com/watch?v=zXE9Q9EUXKg&list=PL8RXTGoT1e8O36qrHCCeqrdIXMCw652MB",
    },
    {
      name: "Giải đề giữa kì vật lý 2",
      img: img_2,
      path: "https://www.youtube.com/watch?v=M8o2Px_Q4BE&list=PL8RXTGoT1e8PhYSNJwYDzVX07S4C4OXuf",
    },
    {
      name: "Buổi 1: Xác suất thống kê",
      img: img_4,
      path: "https://www.youtube.com/watch?v=Ha9RhOGlJqU&lc=Ugz2iPEoHAZbPx-Xo3Z4AaABAg",
    },

    {
      name: "Tìm giá trị lớn nhất, nhỏ nhất trong miền 3D",
      img: matlab,
      path: "/",
    },

    { name: "Mẹo vặt trong giải tích 2", img: img_6, path: "/" },
  ];
  const DocumentTechFreeDefault = [
    {
      name: "Lập trình matlab",
      img: matlab1,
      path: "https://drive.google.com/file/d/18ncsKGqQvFIL2r1xG3H7hH5o8H77hn8g/view?usp=sharing",
    },
    {
      name: "Lập trình hướng đối tượng C++",
      img: oop,
      path: "https://drive.google.com/drive/folders/1THzkn9Wn_DvNl-7IvT8-dTENkYdxdcoe?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Lập trình C",
      img: c,
      path: "https://drive.google.com/drive/folders/1uC5kD3C6LQdUN7Tpx4xX77lFCijyMbqR?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
    {
      name: "Fullstack Mevn nodejs và vuejs",
      img: mevn,
      path: "https://drive.google.com/drive/folders/15o-eQJfrDoS7SckluLo_IoCenYTEDc4m?fbclid=IwAR1SV5HGvDLT2InooPZxfPOylyC_46b6-VsSR-iD83dqaupa52ABUVUBaIk",
    },
  ];
  const [courseFreeDefault, setCourseFreeDefault] = useState(null);
  const [Courses, setCourses] = useState(null);
  const [newfeeds, setNewfeeds] = useState(null);
  const [Videos, setVideos] = useState(null);
  const { id } = useParams();
  const item_page = useRef(12);

  useEffect(() => {
    setCourses(CoursesDefault);
    setNewfeeds(NewfeedsDefault);
    setVideos(VideoDefault);
    document.title = "Tài liệu tổng hợp";
    return () => {};
  }, []);
  useEffect(() => {
    id
      ? window.scrollTo({ top: id, behavior: "smooth" })
      : window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handleResize = () => {
    window.innerWidth < 768
      ? (() => {
          setCourseFreeDefault(() => {
            item_page.current = 4;
            return CourseFreeDefault.slice(0, 4);
          });
          setVideos(() => {
            return VideoDefault.slice(0, 4);
          });
          setNewfeeds(() => {
            return NewfeedsDefault.slice(0, 4);
          });
        })()
      : (() => {
          setCourseFreeDefault(() => {
            item_page.current = 12;
            return CourseFreeDefault.slice(0, 12);
          });
          setVideos(() => {
            return VideoDefault;
          });
          setNewfeeds(() => {
            return NewfeedsDefault;
          });
        })();
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={cx("wrapper")}>
      <div className={cx("course-heading")}>
        <h2>
          <span>Bảng Tin Sinh Viên Bách Khoa</span>
        </h2>
      </div>
      <div className={cx("course-list")}>
        {newfeeds?.map((item, index) => {
          return (
            <div>
              <Card
                link={item.path}
                document={true}
                blank={true}
                key={index}
                title={item.name}
                image={item.img}
              ></Card>
            </div>
          );
        })}
      </div>
      <div className={cx("pagination", { desktop: true })}>
        <Pagination
          onChange={(page) => {
            setNewfeeds(
              NewfeedsDefault?.slice(
                page * item_page.current - item_page.current,
                page * item_page.current
              )
            );
          }}
          defaultCurrent={1}
          total={20}
        ></Pagination>
      </div>

      <div className={cx("course-heading")}>
        <h2>
          <span>Khóa Học Cấp Tốc Về Bờ An Toàn K24 đại trà</span>
          <img src={iconHot}></img>
          <span className={cx("chuamo")}>Mở nhận học viên từ 5/3/2025</span>
        </h2>
      </div>
      <div className={cx("course-list")}>
        {Courses?.map((course, index) => {
          console.log(course);
          return (
            <div>
              <CardM
                link={`/course/${course.id}`}
                lesson={course.lesson}
                linkRegister={course.linkRegister}
                member={course.member}
                key={index}
                title={course.courseName}
                price={course.price}
                oldPrice={course.oldPrice}
                image={course.img}
                tutor={course.tutor}
                soon={course.soon}
                discountText={course.discount}
              ></CardM>
            </div>
          );
        })}
      </div>

      <div className={cx("course-heading")}>
        <h2>
          <span>Khóa học miễn phí</span>
        </h2>
      </div>
      <div className={cx("course-list")}>
        {courseFreeDefault?.map((item, index) => {
          return (
            <div>
              <Card
                video={true}
                link={item.path}
                blank={true}
                document={true}
                key={index}
                title={item.name}
                image={item.img}
              ></Card>
            </div>
          );
        })}
      </div>
      <div className={cx("pagination")}>
        <Pagination
          onChange={(page) => {
            setCourseFreeDefault(
              CourseFreeDefault?.slice(
                page * item_page.current - item_page.current,
                page * item_page.current
              )
            );
          }}
          defaultCurrent={1}
          total={item_page.current === 12 ? 30 : 90}
        ></Pagination>
      </div>
      <div className={cx("course-heading")}>
        <h2>
          <span>Tài liệu công nghệ và IT</span>
        </h2>
      </div>
      <div className={cx("course-list")}>
        {DocumentTechFreeDefault?.map((item, index) => {
          return (
            <div>
              <Card
                blank={true}
                link={item.path}
                document={true}
                key={index}
                title={item.name}
                image={item.img}
              ></Card>
            </div>
          );
        })}
      </div>
      <div className={cx("course-heading")}>
        <h2>
          <span>Video Nổi Bật</span>
        </h2>
      </div>
      <div className={cx("course-list")}>
        {Videos?.map((item, index) => {
          return (
            <div>
              <Card
                blank={true}
                link={item.path}
                document={true}
                video={true}
                key={index}
                title={item.name}
                image={item.img}
              ></Card>
            </div>
          );
        })}
      </div>
      <div className={cx("pagination", { desktop: true })}>
        <Pagination
          onChange={(page) => {
            setVideos(
              VideoDefault?.slice(
                page * item_page.current - item_page.current,
                page * item_page.current
              )
            );
          }}
          defaultCurrent={1}
          total={20}
        ></Pagination>
      </div>

      <div className={cx("link-intro")}>
        <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
        <div>
          <Button
            link={"/home/0"}
            pt5
            text={"Thông Tin Lớp Đại Cương"}
          ></Button>
          <Button
            link={"https://zalo.me/g/sqzmqu522"}
            blank
            pt5
            text={"Nhóm Zalo"}
          ></Button>
          <Button link={"/pt5"} pt5 text={"Tính điểm phương thức 5"}></Button>
          <Button link={"/course/xstk"} pt5 text={"Sự kiện hot"}></Button>
          <Button
            link={"/course/xstk"}
            pt5
            text={"Lớp xác xuất thống kê mới mở"}
          ></Button>
          <Button
            onclick={() => {
              window.scrollTo({ top: 290, behavior: "smooth" });
            }}
            pt5
            text={"Khóa học miễn phí"}
          ></Button>
          <Button
            link={"https://www.youtube.com/@cncp246"}
            pt5
            blank={true}
            text={"Kênh youtube"}
          ></Button>
          <Button
            link={"https://www.facebook.com/groups/495364634751426"}
            pt5
            text={"Nhóm facebook HCMUT-CNCP"}
          ></Button>
          <Button link={"home/550"} pt5 text={"Tài liệu các khoa"}></Button>
          <Button
            onclick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            pt5
            text={"Ôn tập chứng chứng chỉ tin học"}
          ></Button>

          <Button
            link={"/document"}
            pt5
            onclick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            text={"Tài liệu anh văn xếp lớp"}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default Document;
