import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { Radio } from "antd";
import {
  faBook,
  faMinus,
  faTimeline,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./PT5.module.scss";
import subject from "../../../assets/images/bachkhoa.png";
import Subject from "../../Elements/Subject/Subject";
import Button from "../../Elements/Button/Button";
import Loading from "../../Elements/Loading/Loading";
import Logo from "../../../assets/images/facebook.png";
import Zalo from "../../../assets/images/icon/zalo.png";
import youtube from "../../../assets/images/icon/youtube.png";
const cx = classNames.bind(styles);
function Selection() {
  const DataDefaultPass = [
    {
      name: "Chương trình đại trà",

      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
    {
      name: "Chương trình OISP( giảng dạy bằng tiếng Anh, tiên tiến,...)",
      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
  ];
  const DataDefaultNoPass = [
    {
      name: "Chương trình đại trà",

      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
    {
      name: "Chương trình OISP( giảng dạy bằng tiếng Anh, tiên tiến,...)",
      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
  ];
  const DataDepartment = [
    {
      name: "Khoa học máy tính",
      price: 93.51,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật máy tính",
      price: 92.08,
      path: "/learning/cse",
    },
    {
      name: "Điện - Điện tử - Viễn thông - Tự động hóa - Thiết kế Vi mạch (Nhóm ngành)",
      price: 88.92,
      path: "/learning/ddt",
    },
    {
      name: "Kỹ thuật cơ khí",
      price: 82.1,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật cơ điện tử",
      price: 90.37,
      path: "/learning/ck",
    },
    {
      name: "Dệt - May (Nhóm ngành)",
      price: 61.68,
      path: "/learning/ck",
    },
    {
      name: "Logistics và Hệ thống Công nghiệp",
      price: 89.0,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật nhiệt",
      price: 80.01,
      path: "/learning/ck",
    },
    {
      name: "Hóa - Thực phẩm - Sinh học (Nhóm ngành)",
      price: 85.96,
      path: "/learning/kthh",
    },
    {
      name: "Xây dựng và Quản lý Dự án Xây dựng (Nhóm ngành)",
      price: 68.9,
      path: "/learning/ktxd",
    },
    {
      name: "Kiến trúc",
      price: 78.72,
      path: "/learning/ktxd",
    },
    {
      name: "Kinh tế Xây dựng",
      price: 65.1,
      path: "/learning/ktxd",
    },
    {
      name: "Dầu khí - Địa chất (Nhóm ngành)",
      price: 73.46,
      path: "/learning/dcvdk",
    },
    {
      name: "Địa Kỹ thuật Xây dựng",
      price: 61.53,
      path: "/learning/ktxd",
    },
    {
      name: "Kỹ thuật vật liệu",
      price: 76.11,
      path: "/learning/cnvl",
    },
    {
      name: "Vật lý kỹ thuật",
      price: 82.07,
      path: "/learning/khud",
    },
    {
      name: "Cơ kỹ thuật",
      price: 83.0,
      path: "/learning/khud",
    },
    {
      name: "Khoa học dữ liệu",
      price: 91.27,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật ô tô",
      price: 86.91,
      path: "/learning/ktgt",
    },
    {
      name: "(Song ngành) Tàu thủy - Hàng không",
      price: 83.76,
      path: "/learning/ktgt",
    },
    {
      name: "Quản lý công nghiệp",
      price: 85.87,
      path: "/learning/qlcn",
    },
    {
      name: "Tài nguyên và Môi trường",
      price: 68.87,
      path: "/learning/mtvtn",
    },
    {
      name: "Bảo dưỡng công nghiệp",
      price: 72.71,
      path: "/learning/bdcn",
    },
  ];

  const DataOISP = [
    // B. CHƯƠNG TRÌNH TIÊN TIẾN (DẠY VÀ HỌC BẰNG TIẾNG ANH)
    {
      name: "Kỹ thuật Điện - Điện tử",
      price: 85.23,
      path: "/learning/ddt",
    },

    // C. CHƯƠNG TRÌNH DẠY VÀ HỌC BẰNG TIẾNG ANH
    {
      name: "Khoa học Máy tính",
      price: 92.92,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật Máy tính",
      price: 89.34,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật Cơ khí",
      price: 73.08,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật Cơ Điện tử",
      price: 86.67,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật Robot",
      price: 81.22,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật Hóa học",
      price: 71.87,
      path: "/learning/kthh",
    },
    {
      name: "Công nghệ Sinh học",
      price: 78.79,
      path: "/learning/kthh",
    },
    {
      name: "Công nghệ Thực phẩm",
      price: 66.79,
      path: "/learning/kthh",
    },
    {
      name: "Quản lý Dự án Xây dựng và Kỹ thuật Xây dựng (Nhóm ngành)",
      price: 65.1,
      path: "/learning/ktxd",
    },
    {
      name: "Kiến trúc Cảnh quan",
      price: 67.87,
      path: "/learning/ktxd",
    },
    {
      name: "Kỹ thuật Dầu khí",
      price: 64.31,
      path: "/learning/dcvdk",
    },
    {
      name: "Quản lý Công nghiệp",
      price: 72.26,
      path: "/learning/qlcn",
    },
    {
      name: "Tài nguyên và Môi trường",
      price: 68.43,
      path: "/learning/mtvtn",
    },
    {
      name: "Logistics và Hệ thống Công nghiệp",
      price: 82.74,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật Vật liệu Công nghệ cao",
      price: 64.4,
      path: "/learning/cnvl",
    },
    {
      name: "Kỹ thuật Y Sinh",
      price: 63.59,
      path: "/learning/kthh",
    },
    {
      name: "Kỹ thuật Ô tô",
      price: 73.19,
      path: "/learning/ktgt",
    },
    {
      name: "Kỹ thuật Hàng không",
      price: 81.67,
      path: "/learning/ktgt",
    },

    // D. CHƯƠNG TRÌNH ĐỊNH HƯỚNG NHẬT BẢN
    {
      name: "Khoa học Máy tính - CT tiếng Nhật",
      price: 88.48, // = 88.48
      path: "/learning/cse",
    },
    {
      name: "Cơ Kỹ thuật - CT tiếng Nhật",
      price: 76.39, // = 76.39
      path: "/learning/khud",
    },
  ];

  const [statusCourseBasic, setStatusCourseBasic] = useState(true);
  const [statusCourseAdvance, setStatusCourseAdvance] = useState(false);
  const [all9, setAll9] = useState(null);
  const [math10, setMath10] = useState(null);
  const [math11, setMath11] = useState(null);
  const [math12, setMath12] = useState(null);
  const [physic10, setPhysic10] = useState(null);
  const [physic11, setPhysic11] = useState(null);
  const [physic12, setPhysic12] = useState(null);
  const [chemistry10, setChemistry10] = useState(null);
  const [chemistry11, setChemistry11] = useState(null);
  const [chemistry12, setChemistry12] = useState(null);
  const [subject1, setSubject1] = useState(null);
  const [subject2, setSubject2] = useState(null);
  const [subject3, setSubject3] = useState(null);
  const [dgnl, setDgnl] = useState(null);
  const [DGNL_QD, setDGNL_QD] = useState(0);
  const [THPT_QD, setTHPT_QD] = useState(0);
  const [TNTHPT_QD, setTNTHPT_QD] = useState(0);
  const [UTTT_QD, setUTTT_QD] = useState(0);
  const [UT_QD, setUT_QD] = useState(0);
  const [UT, setUT] = useState(0);
  const [UTDT, setUTDT] = useState(0);
  const [UTKV, setUTKV] = useState(0);
  const [DGNL_Ngonngu, setDGNL_Ngonngu] = useState(null);
  const [DGNL_Math, setDGNL_Math] = useState(null);
  const [DGNL_Logic, setDGNL_Logic] = useState(null);
  const [DiemHL, setDiemHL] = useState(0);
  const [PT5, setPT5] = useState(0);
  const [dataPass, setDataPass] = useState(DataDefaultPass);
  const [dataNoPass, setNoDataPass] = useState(DataDefaultPass);
  const [textInput, setTextInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [texOpacity, setTexOpacity] = useState(false);
  const [valueKV, setValueKV] = useState(1);
  const [valueCase, setValueCase] = useState(1);
  const [valueUT, setValueUT] = useState(0);

  const onChangeKV = (e) => {
    setValueKV(e.target.value);
  };
  const onChangeCase = (e) => {
    setValueCase(e.target.value);
  };

  const onChangeUT = (e) => {
    setValueUT(e.target.value);
  };
  function handleResize() {
    setTextInput(window.innerWidth > 576 ? "Nhập điểm..." : "0");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkNumber = (value) => {
    return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp.
  };
  useEffect(() => {
    document.title = "Tính điểm xét tuyển phương thức 5 Bách Khoa 2025";
  });
  function demSoChuSoThapPhan(so) {
    // Kiểm tra nếu số là số nguyên
    if (Number.isInteger(so)) {
      return 0;
    }

    // Chuyển đổi số thành chuỗi
    const soChuoi = so.toString();

    // Tìm vị trí của dấu thập phân
    const viTriThapPhan = soChuoi.indexOf(".");

    // Nếu không tìm thấy dấu thập phân, số là số nguyên
    if (viTriThapPhan === -1) {
      return 0;
    }

    // Tính số lượng chữ số sau dấu thập phân
    return soChuoi.length - viTriThapPhan - 1;
  }
  const handleCalculate = () => {
    setIsLoading(true);
    setIsResult(false);
    setTexOpacity(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsResult(true);
      setTexOpacity(false);
    }, 1000);
    const Score9 =
      math10 * 1 +
      math11 * 1 +
      math12 * 1 +
      physic10 * 1 +
      physic11 * 1 +
      physic12 * 1 +
      chemistry10 * 1 +
      chemistry11 * 1 +
      chemistry12 * 1;
    const trunghoc = subject1 * 1 + subject2 * 1 + subject3 * 1;
    const HOCBA = all9 * 1 === 0 || all9 * 1 === null ? Score9 : all9;
    let dgnl_qd = 0;
    let utkv = 0;
    let utdt = 0;
    let diemuutien = 0;

    switch (valueCase * 1) {
      case 1:
        dgnl_qd = (DGNL_Math * 2 + DGNL_Logic * 1 + DGNL_Ngonngu * 1) / 15;
        setDGNL_QD(dgnl_qd);
        break;
      case 2:
        dgnl_qd = (trunghoc / 3) * 10 * 0.75;
        setDGNL_QD(dgnl_qd);
        break;
    }
    switch (valueKV * 1) {
      case 1:
        utkv = 0.75;
        break;
      case 2:
        utkv = 0.25;
        break;
      case 3:
        utkv = 0;
        break;
      case 4:
        utkv = 0.5;
        break;
    }
    setUTKV(utkv);
    switch (valueUT * 1) {
      case 1:
        utdt = 2;
        break;
      case 2:
        utdt = 1;
        break;
      case 3:
        utdt = 0;
        break;
    }
    setUTDT(utdt);
    let utqd = 0;
    if (((utdt + utkv) / 30) * 100 + UTTT_QD * 1 < 10) {
      console.log("UTQD", 1);
      utqd = ((utdt + utkv) / 30) * 100 + UTTT_QD * 1;
    } else {
      console.log("UTQD", 2);
      utqd = 10;
    }

    setUT_QD(utqd);
    setTHPT_QD((HOCBA * 10) / 9);
    setTNTHPT_QD((trunghoc / 3) * 10);
    let DiemHocLuc = 0;
    const rateTHPT = 0.2;
    const rateHocBa = 0.1;
    const rateDGNL = 0.7;
    DiemHocLuc =
      rateDGNL * dgnl_qd +
      ((rateTHPT * trunghoc) / 3) * 10 +
      (rateHocBa * HOCBA * 10) / 9;
    setDiemHL(DiemHocLuc);
    if (DiemHocLuc < 75) {
      diemuutien = utqd;
    } else {
      diemuutien = ((100 - DiemHocLuc) / 25) * utqd;
    }
    setUT(diemuutien);
    UpdateResult(DiemHocLuc + diemuutien);
    setPT5(DiemHocLuc + diemuutien);
  };
  const UpdateResult = (result) => {
    DataDefaultPass[0].assignments = handleFilterPassNormal(result);
    DataDefaultPass[1].assignments = handleFilterPassOISP(result);
    DataDefaultNoPass[0].assignments = handleFilterNoPassNormal(result);
    DataDefaultNoPass[1].assignments = handleFilterNoPassOISP(result);
    setDataPass(DataDefaultPass);
    setNoDataPass(DataDefaultNoPass);
  };
  const handleFilterPassOISP = (score) => {
    const data = DataOISP.filter((course) => {
      return course.price < score || course.price === score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterNoPassOISP = (score) => {
    const data = DataOISP.filter((course) => {
      return course.price > score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterPassNormal = (score) => {
    const data = DataDepartment.filter((course) => {
      return course.price < score || course.price === score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterNoPassNormal = (score) => {
    const data = DataDepartment.filter((course) => {
      return course.price > score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  return (
    <div className={cx("container")}>
      <div>
        <div className={cx("intro")}>
          <h1>
            Công Cụ Tính Điểm Phương Thức Xét Tuyển Tổng Hợp Đại Học Bách Khoa
            HCM 2025
          </h1>
          <h4>
            Công cụ tính điểm phương thức xét tuyển tổng hợp đại học Bách Khoa
            HCM 2025 xây dựng theo công thức dự kiến năm 2025 của trường, chi
            tiết tại đây:{" "}
            <a
              target="blank"
              href="https://hcmut.edu.vn/tuyen-sinh/dai-hoc-chinh-quy/phuong-thuc-tuyen-sinh/phuong-thuc-tuyen-sinh-nam-2025-dukien"
            >
              bài viết
            </a>
            . Ngày cập nhật: 30/3/2025
          </h4>
          {/* <span>
            Trang web Bách Khoa được phát triển bởi{" "}
            <a
              target="_blank"
              className={cx("press")}
              href="https://www.facebook.com/profile.php?id=100086390823801"
            >
              Nguyễn Quốc Vương K19
            </a>
            , hiện tại mình đang học Thạc Sĩ tại trường Bách Khoa HCM, đồng thời
            có mở lớp học thêm các môn đại cương cho tân sinh viên K25{" "}
            <a target="_blank" href="https://bachkhoacncp.com/home/550">
              Xem lớp
            </a>{" "}
            với kinh nghiệm dạy 6 năm, hơn 3000 sinh viên Bách Khoa mỗi năm.{" "}
            <a
              target="_blank"
              href="https://www.facebook.com/share/p/18qNd4dyg7/"
            >
              Thành tích K24
            </a>
          </span> */}
          <span>
            Trang web Bách Khoa được phát triển bởi{" "}
            <a
              target="_blank"
              className={cx("press")}
              href="https://www.facebook.com/profile.php?id=100086390823801"
            >
              Nguyễn Quốc Vương K19
            </a>
            , hiện tại mình đang học Thạc Sĩ tại trường Bách Khoa HCM. Ngoài
            công cụ tính điểm phương thức xét tuyển tổng hợp năm 2025 còn là nơi
            đăng rất nhiều tài liệu học tập rất có giá trị cho sinh viên Bách
            Khoa. Mời quý phụ huynh và học sinh trải nghiệm web ạ. Liên hệ với
            admin trang web tại{" "}
            <a href="https://www.facebook.com/nguyen.quoc.vuong.947677/">
              Quốc Vương
            </a>
          </span>

          <div>
            <div className={cx("advertisement-mobile")}>
              <div className={cx("container-advertisement")}>
                <div className={cx("container-image")}>
                  <img className={cx("image")} src={subject}></img>
                  <h5>Nhóm chia sẻ tài liệu ở đại học</h5>
                  <Button
                    link={"https://www.facebook.com/groups/hcmutcncp"}
                    text={"Xem nhóm"}
                  ></Button>
                </div>

                <div className={cx("detail-advertisement")}>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faUsers}
                    ></FontAwesomeIcon>
                    <span>Sinh viên Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faFacebook}
                    ></FontAwesomeIcon>
                    <span>Nhóm học tập lớn nhất Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faBook}
                    ></FontAwesomeIcon>
                    <span>Chia sẻ tài liệu free</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faTimeline}
                    ></FontAwesomeIcon>
                    <span>Anh chị nhiệt tình</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faNewspaper}
                    ></FontAwesomeIcon>
                    <span>Tài liệu mới&chất lượng</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("document")}>
              <div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Chọn trường hợp của thí sinh</span>
                  </div>

                  <div className={cx("input-score")}>
                    <Radio.Group onChange={onChangeCase} value={valueCase}>
                      <Radio value={1}>Có thi ĐGNL </Radio>
                      <Radio value={2}>Không thi ĐGNL</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>
                      Nhập điểm học bạ ( *Dùng dấu (.) cho số thập phân* )
                    </span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input", { input_mobile: true })}>
                      <span>Tổng</span>
                      <input
                        placeholder="Nhập điểm tổng 9 môn"
                        type="text"
                        value={all9}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setAll9(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <span>
                    <i>hoặc</i>
                  </span>
                  <div className={cx("input-score")}>
                    <div className={cx("input")}>
                      <span>Toán 10</span>

                      <input
                        value={math10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 10</span>

                      <input
                        value={physic10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 10</span>

                      <input
                        value={chemistry10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Toán 11</span>

                      <input
                        value={math11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 11</span>

                      <input
                        value={physic11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 11</span>

                      <input
                        value={chemistry11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Toán 12</span>

                      <input
                        value={math12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 12</span>

                      <input
                        value={physic12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 10</span>

                      <input
                        value={chemistry12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Nhập điểm thi THPT</span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input")}>
                      <span>Môn 1</span>

                      <input
                        value={subject1}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject1(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Môn 2</span>

                      <input
                        value={subject2}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject2(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Môn 3</span>

                      <input
                        value={subject3}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject3(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Nhập các cột điểm thành phần của thi ĐGNL</span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input")}>
                      <span>Ngôn ngữ</span>

                      <input
                        value={DGNL_Ngonngu}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setDGNL_Ngonngu(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Toán học</span>

                      <input
                        value={DGNL_Math}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setDGNL_Math(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Tư duy KH</span>

                      <input
                        value={DGNL_Logic}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setDGNL_Logic(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                  <span>
                    Lưu ý: Cột điểm ngôn ngữ sẽ bao gồm cả tiếng việt và tiếng
                    anh
                  </span>
                </div>

                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Chọn khu vực sinh sống</span>
                  </div>

                  <div className={cx("input-score")}>
                    <Radio.Group onChange={onChangeKV} value={valueKV}>
                      <Radio value={1}>KV1</Radio>
                      <Radio value={2}>KV2</Radio>
                      <Radio value={3}>KV3</Radio>
                      <Radio value={4}>KV2-NT</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Chọn đối tượng ưu tiên</span>
                  </div>

                  <div className={cx("input-score")}>
                    <Radio.Group onChange={onChangeUT} value={valueUT}>
                      <Radio value={1}>Đối tượng ưu tiên 1</Radio>
                      <Radio value={2}>Đối tượng ưu tiên 2</Radio>
                      <Radio value={0}>Không có</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>
                      Nhập điểm ưu tiên thành tích cá nhân và văn thể mỹ
                    </span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input", { input_mobile: true })}>
                      <span>Điểm thành tích</span>
                      <input
                        value={UTTT_QD}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setUTTT_QD(e.target.value);
                        }}
                        placeholder={"Điểm tự đánh giá..."}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              {isResult || (
                <div className={cx("Group")}>
                  <div className={cx("sub-group")}>
                    <a
                      href="https://www.youtube.com/watch?v=j-xa2V5jz7c"
                      target="_blank"
                      className={cx("logo-container")}
                    >
                      <img className={cx("logo")} src={youtube}></img>
                      <h2 className={cx("header-title")}>
                        Hướng dẫn cách tính năm 2025
                      </h2>
                    </a>
                  </div>
                  <div className={cx("sub-group")}>
                    <a
                      href="https://www.facebook.com/groups/hcmutcncp"
                      target="_blank"
                      className={cx("logo-container")}
                    >
                      <img className={cx("logo")} src={Logo}></img>
                      <h2 className={cx("header-title")}>
                        Nhóm facebook hỏi đáp tuyển sinh
                      </h2>
                    </a>
                  </div>
                  <div className={cx("sub-group")}>
                    <a
                      href="https://zalo.me/g/ymomdq810"
                      target="_blank"
                      className={cx("logo-container")}
                    >
                      <img className={cx("logo")} src={Zalo}></img>
                      <h2 className={cx("header-title")}>
                        Nhóm Zalo hỏi đáp tuyển sinh 2025
                      </h2>
                    </a>
                  </div>
                </div>
              )}

              <div className={cx("bt-cal")}>
                <Button
                  onclick={handleCalculate}
                  cal
                  opacityText={texOpacity}
                  text={"Tính toán"}
                >
                  {isLoading && <Loading></Loading>}
                </Button>
              </div>
            </div>
            <div className={cx("document")}>
              {isResult && (
                <>
                  <div className={cx("Group")}>
                    <div className={cx("sub-group")}>
                      <a
                        href="https://www.youtube.com/watch?v=j-xa2V5jz7c"
                        target="_blank"
                        className={cx("logo-container")}
                      >
                        <img className={cx("logo")} src={youtube}></img>
                        <h2 className={cx("header-title")}>
                          Hướng dẫn cách tính năm 2025
                        </h2>
                      </a>
                    </div>
                    <div className={cx("sub-group")}>
                      <a
                        href="https://www.facebook.com/groups/hcmutcncp"
                        target="_blank"
                        className={cx("logo-container")}
                      >
                        <img className={cx("logo")} src={Logo}></img>
                        <h2 className={cx("header-title")}>
                          Nhóm facebook hỏi đáp tuyển sinh
                        </h2>
                      </a>
                    </div>
                    <div className={cx("sub-group")}>
                      <a
                        href="https://zalo.me/g/ymomdq810"
                        target="_blank"
                        className={cx("logo-container")}
                      >
                        <img className={cx("logo")} src={Zalo}></img>
                        <h2 className={cx("header-title")}>
                          Nhóm Zalo hỏi đáp tuyển sinh 2025
                        </h2>
                      </a>
                    </div>
                  </div>
                  <div>
                    <h2>Kết quả</h2>
                    <div
                      style={{
                        color: "#333",
                      }}
                      className={cx("title-result")}
                    >
                      <span>Điểm học bạ quy đổi: {THPT_QD.toFixed(2)}</span>
                      <span>Điểm năng lực: {DGNL_QD.toFixed(2)} </span>
                      <span>Điểm THPTQG quy đổi: {TNTHPT_QD.toFixed(0)}</span>
                      <span>Điểm ưu tiên: {UT.toFixed(2)} </span>
                    </div>
                    <div
                      style={{
                        color: "#333",
                        fontSize: "15px",
                      }}
                    >
                      <div
                        className={cx("score")}
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          marginBottom: "16px",
                          color: "#333",
                        }}
                      >
                        <span>
                          Điểm tổng xét tuyển tổng hợp năm 2025:{" "}
                          {PT5.toFixed(2)}
                        </span>
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid #e6e6e6",
                          paddingTop: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "12px",
                            color: "#333",
                            margin: "10px 0",
                          }}
                        >
                          Giải thích thêm: (dựa theo quy định tuyển sinh dự kiến
                          2025 của trường{" "}
                          <a
                            href="https://hcmut.edu.vn/tuyen-sinh/dai-hoc-chinh-quy/phuong-thuc-tuyen-sinh/phuong-thuc-tuyen-sinh-nam-2025-dukien"
                            style={{
                              color: "#4285f4",
                              textDecoration: "none",
                            }}
                          >
                            tại đây
                          </a>{" "}
                          )
                        </h4>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#f8f9fa",
                            padding: "10px",
                            borderRadius: "4px",
                            margin: "12px 0",
                            fontWeight: "600",
                            color: "#333",
                          }}
                        >
                          [Điểm Xét tuyển] = [Điểm học lực] + [Điểm ưu tiên]
                        </div>

                        <div
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            marginTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          Tính điểm học lực
                        </div>

                        {valueCase == 1 ? (
                          <div style={{ lineHeight: "1.4" }}>
                            <p style={{ margin: "5px 0" }}>
                              Bạn thuộc nhóm có tham gia thi ĐGNL 2025, nên điểm
                              năng lực của bạn được tính như sau:
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "8px 0",
                                padding: "8px",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "4px",
                                color: "#333",
                              }}
                            >
                              ({DGNL_Math} x 2 + {DGNL_Logic} x 1 +{" "}
                              {DGNL_Ngonngu} x 1) / 15 = {DGNL_QD.toFixed(2)}
                            </div>
                          </div>
                        ) : (
                          <div style={{ lineHeight: "1.4" }}>
                            <p style={{ margin: "5px 0" }}>
                              Bạn thuộc nhóm không tham gia thi ĐGNL 2025, nên
                              điểm năng lực của bạn được tính như sau:
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "8px",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "4px",
                                color: "#333",
                              }}
                            >
                              Điểm TNTHPT quy đổi X 0.75 ={" "}
                              {(TNTHPT_QD * 0.75).toFixed(2)} ={" "}
                              {DGNL_QD.toFixed(2)}
                            </div>
                          </div>
                        )}
                        <div style={{ marginBottom: "6px", lineHeight: "1.4" }}>
                          <p style={{ margin: "5px 0" }}>
                            Điểm THPT QG quy đổi:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              padding: "8px",
                              backgroundColor: "#f8f9fa",
                              borderRadius: "4px",
                              color: "#333",
                            }}
                          >
                            Tổng 3 môn/30x100=[{subject1}+{subject2}+{subject3}
                            ]/30x100={TNTHPT_QD.toFixed(2)}
                          </div>
                        </div>
                        <div style={{ marginBottom: "6px", lineHeight: "1.4" }}>
                          <p style={{ margin: "5px 0" }}>
                            Điểm Học bạ quy đổi:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              padding: "8px",
                              backgroundColor: "#f8f9fa",
                              borderRadius: "4px",
                              color: "#333",
                            }}
                          >
                            Tổng 9 môn/9x100={THPT_QD.toFixed(2)}
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: "16px",
                            lineHeight: "1.4",
                            padding: "8px",
                            backgroundColor: "#f8f9fa",
                            borderRadius: "4px",
                            color: "#333",
                          }}
                        >
                          [Điểm học lực] = [Điểm năng lực] × 70% + [Điểm THPTQG
                          quy đổi] × 20% + [Học bạ quy đổi] × 10% ={" "}
                          {DiemHL.toFixed(2)}
                        </div>

                        <div
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            marginTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          Tính điểm ưu tiên
                        </div>

                        <ul
                          style={{
                            paddingLeft: "20px",
                            marginBottom: "12px",
                            lineHeight: "1.4",
                          }}
                        >
                          <li style={{ marginBottom: "8px" }}>
                            Điểm ưu tiên thành tích = {UTTT_QD} (từ 0 đến 10
                            điểm, chưa có quy định cụ thể, nên luôn cho bằng 0)
                          </li>
                          <li>
                            Điểm ưu tiên khu vực đối tượng quy đổi:
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "8px 0",
                                padding: "8px",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "4px",
                                color: "#333",
                              }}
                            >
                              (UT khu vực + UT đối tượng)/30x100 = {UTKV + UTDT}
                              /30x10 = {(((UTKV + UTDT) / 30) * 100).toFixed(
                                2
                              )}{" "}
                              (<i>Chuyển về thang 100</i>)
                            </div>
                          </li>
                        </ul>
                        <div
                          style={{ marginBottom: "12px", lineHeight: "1.4" }}
                        >
                          Điểm ưu tiên quy đổi= [Điểm ưu tiên thành tích] +
                          [Điểm ưu tiên khu vực, đối tượng quy đổi] ={" "}
                          {UT_QD.toFixed(2)}{" "}
                          {((UTKV + UTDT) / 30) * 100 + UTTT_QD * 1 > 10 && (
                            <i>
                              (vì tổng điểm ưu tiên quy đổi{" "}
                              {(
                                ((UTKV + UTDT) / 30) * 100 +
                                UTTT_QD * 1
                              ).toFixed(2)}{" "}
                              lớn hơn 10 nên theo quy định sẽ quy về 10 )
                            </i>
                          )}
                        </div>
                        <div
                          style={{ marginBottom: "12px", lineHeight: "1.4" }}
                        >
                          {DiemHL < 75 ? (
                            <span>
                              Vì điểm học lực bé hơn 75 nên điểm ưu tiên = điểm
                              ưu tiên quy đổi = {UT_QD.toFixed(2)}
                            </span>
                          ) : (
                            <div>
                              <p>
                                Vì điểm học lực lớn hơn 75 nên điểm ưu tiên được
                                tính như sau:
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "8px 0",
                                  padding: "8px",
                                  backgroundColor: "#f8f9fa",
                                  borderRadius: "4px",
                                  color: "#333",
                                }}
                              >
                                [100 - {DiemHL.toFixed(2)}] / 25 ×{" "}
                                {UT_QD.toFixed(2)} = {UT.toFixed(2)}
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            marginTop: "16px",
                            marginBottom: "12px",
                            lineHeight: "1.4",
                            paddingTop: "10px",
                            borderTop: "1px dashed #e6e6e6",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "4px",
                              color: "#333",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            Vậy tổng điểm xét tuyển của bạn là [Điểm học lực] +
                            [Điểm ưu tiên] = {DiemHL.toFixed(2)} +{" "}
                            {UT.toFixed(2)} = {PT5.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={cx("score")}>
                      <span>
                        Điểm tổng xét tuyển tổng hợp năm 2025: {PT5}
                      </span>
                    </div>
                    <div>
                      <h4>
                        Giải thích thêm: (dựa theo quy định tuyển sinh dự kiến
                        2025 của trường{" "}
                        <a href="https://hcmut.edu.vn/tuyen-sinh/dai-hoc-chinh-quy/phuong-thuc-tuyen-sinh/phuong-thuc-tuyen-sinh-nam-2025-dukien">
                          tại đây
                        </a>{" "}
                        )
                      </h4>
                      <h4
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        [Điểm Xét tuyển] = [Điểm học lực] + [Điểm ưu tiên]
                      </h4>
                      <div>Tính điểm học lực</div>
                      {valueCase == 1 ? (
                        <span>
                          Bạn thuộc nhóm có tham gia thi ĐGNL 2025, nên điểm
                          năng lực của bạn được tính như sau:
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            ({DGNL_Math} * 2 +{DGNL_Logic} * 1 + {DGNL_Ngonngu}{" "}
                            * 1) / 15 = {DGNL_QD.toFixed(2)}
                          </div>
                        </span>
                      ) : (
                        <span>
                          Bạn thuộc nhóm không tham gia thi ĐGNL 2025, nên điểm
                          năng lực của bạn được tính như sau:
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          ></div>
                          Điểm TNTHPT quy đổi X 0.75 ={" "}
                          {(TNTHPT_QD * 0.75).toFixed(2)} = {DGNL_QD};<br></br>
                        </span>
                      )}
                      <div>
                        [Điểm học lực] = [Điểm năng lực] × 70% + [Điểm THPTQG
                        quy đổi] × 20% + [Học bạ quy đổi] × 10% ={" "}
                        {DiemHL.toFixed(2)}
                      </div>
                      <span>Tính điểm ưu tiên</span>
                      <br></br>
                      <span>
                        Điểm ưu tiên quy đổi = [Điểm ưu tiên thành tích] + [Điểm
                        ưu tiên khu vực đối tượng quy đổi] = {UT_QD.toFixed(2)}
                      </span>
                      <ul>
                        <li>
                          Điểm ưu tiên thành tích = 0 (từ 0 đến 10 điểm, chưa có
                          quy định cụ thể, nên luôn cho bằng 0)
                        </li>
                        <li>
                          Điểm ưu tiên khu vực đối tượng quy đổi:
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            (UT khu vực + UT đối tượng)/30x100 = {UTKV + UTDT}
                            /30x100 = {UT_QD.toFixed(2)} (
                            <i>Chuyển về thang 100</i>)
                          </div>
                        </li>
                      </ul>
                      <div>
                        {DiemHL < 75 ? (
                          <span>
                            {" "}
                            Vì điểm học lực bé hơn 75 nên điểm ưu tiên = điểm ưu
                            tiên quy đổi = {UT_QD.toFixed(2)}
                          </span>
                        ) : (
                          <div>
                            Vì điểm học lực lớn hơn 75 nên điểm ưu tiên được
                            tính như sau:
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              (100 - [{DiemHL.toFixed(2)}]) / 25 ×{" "}
                              {UT_QD.toFixed(2)} = {UT}
                            </div>
                          </div>
                        )}{" "}
                      </div>
                      <div>
                        <span>
                          Vậy tổng điểm xét tuyển của bạn là [Điểm học
                          lực]+[Điểm ưu tiên] ={DiemHL.toFixed(2)}+
                          {UT}={PT5}{" "}
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <div className={cx("program")}>
                      <span>
                        Với mức điểm <span>{PT5.toFixed(2)}</span> bạn sẽ nhận
                        được kết quả như sau: ( so với điểm chuẩn 2024 đã được
                        quy về thang 100 vì năm ngoái thang 90 nếu so thang 100
                        với thang 90 thì quá vô nghĩa,{" "}
                        <a
                          href="https://drive.google.com/drive/u/1/folders/1GEGs7Q3Nehv32KEEBsC2WzHhToEVjQNX"
                          target="_blank"
                          className={cx("score-standard")}
                        >
                          Xem điểm các năm
                        </a>{" "}
                        )
                      </span>
                      <a
                        target="_blank"
                        className={cx("hightline")}
                        href={"https://www.youtube.com/watch?v=j-xa2V5jz7c"}
                      >
                        Cách tính điểm 2025
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href="https://hcmut.edu.vn/bai-viet/chuong-trinh-dao-tao-tu-khoa-2019"
                      >
                        Xem chương trình học từng ngành
                      </a>
                    </div>
                    <div
                      onClick={() => {
                        setStatusCourseBasic((state) => {
                          return !state;
                        });
                      }}
                      className={cx("basic-course")}
                    >
                      <div>
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faMinus}
                        ></FontAwesomeIcon>
                        <span>1. Các ngành bạn đủ điểm</span>
                      </div>
                      <span>
                        {dataPass[0].assignments.length +
                          dataPass[1].assignments.length}
                        /{DataDepartment.length + DataOISP.length} ngành
                      </span>
                    </div>
                    {statusCourseBasic && (
                      <div>
                        <div>
                          {dataPass?.map((ass) => {
                            return (
                              <Subject
                                PT5
                                active
                                name={ass.name}
                                weight={"Điểm"}
                                data={ass.assignments}
                              ></Subject>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        setStatusCourseAdvance((state) => {
                          return !state;
                        });
                      }}
                      className={cx("basic-course")}
                    >
                      <div>
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faMinus}
                        ></FontAwesomeIcon>
                        <span>2. Các khoa/ngành bạn không đủ điểm</span>
                      </div>
                      <span>
                        {dataNoPass[0].assignments.length +
                          dataNoPass[1].assignments.length}
                        /{DataDepartment.length + DataOISP.length} ngành
                      </span>
                    </div>
                    {statusCourseAdvance && (
                      <div>
                        <div>
                          {dataNoPass?.map((ass) => {
                            return (
                              <Subject
                                active
                                PT5
                                name={ass.name}
                                weight={"Điểm"}
                                data={ass.assignments}
                              ></Subject>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={cx("content")}></div>
      </div>
      <div className={cx("advertisement-desktop")}>
        <div className={cx("link-intro")}>
          <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
          <div>
            <Button
              link={"/home/250"}
              PT5
              text={"Thông Lớp Đại Cương"}
            ></Button>
            <Button
              link={"https://zalo.me/g/ymomdq810"}
              blank
              PT5
              text={"Nhóm Zalo"}
            ></Button>
            <Button link={"/home/300"} PT5 text={"Tài Liệu Các Khoa"}></Button>
            <Button
              link={"document/290"}
              PT5
              text={"Khóa học miễn phí"}
            ></Button>
          </div>
        </div>
        <div className={cx("container-advertisement")}>
          <div className={cx("container-image")}>
            <img className={cx("image")} src={subject}></img>
          </div>
          <h5>Nhóm chia sẻ tài liệu ở đại học</h5>
          <Button
            link={"https://www.facebook.com/groups/hcmutcncp"}
            text={"Xem nhóm"}
          ></Button>
          <div className={cx("detail-advertisement")}>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faUsers}
              ></FontAwesomeIcon>
              <span>Sinh viên Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faFacebook}
              ></FontAwesomeIcon>
              <span>Nhóm học tập lớn nhất Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faBook}
              ></FontAwesomeIcon>
              <span>Chia sẻ tài liệu free</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faTimeline}
              ></FontAwesomeIcon>
              <span>Anh chị nhiệt tình</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faNewspaper}
              ></FontAwesomeIcon>
              <span>Tài liệu mới&chất lượng</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selection;
